<template>
  <div id="examining">
    <header>
      <img
          class="notice-icon"
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice.png"
          alt=""
      />
      <div class="notice-content">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/notice-content.png"
            alt=""
        />
      </div>
    </header>
    <main>
      <div class="product-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/main-title.png"
            alt=""
            class="product-container-title"
        />
        <div class="product-loan-info-container">
          <div class="product-preview-loan-money">
            <div><em>¥</em>{{ previewMoney }}</div>
            <div>预估额度</div>
          </div>
          <div class="product-preview-time">
            <div>3-36期</div>
            <div>预估期限</div>
          </div>
        </div>
        <div class="product-info-container">
          <img
              :src="product.logo"
              alt=""
              class="product-logo"
          />
          <div>
            <div class="product-name">{{ product.name }}</div>
            <div class="product-company-name">{{ product.companyName }}</div>
          </div>
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/customer-phone-icon.gif"
              alt=""
              class="customer-phone-icon"
              @click="doCall"
          />
        </div>
        <div class="product-address-container">
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/address-icon.png"
              alt=""
          />
          <span>机构地址：{{ product.address }}</span>
        </div>
      </div>
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/step-image.png"
          alt=""
          class="step-image"
      />
      <div v-show="showSubmitButton">
        <div
            class="submit-button"
            @click="clickBottomButton"
            v-throttle="2000"
        >
          {{ buttonToDownload ? '下载APP，立即提现' : '领取更多额度' }}
          <img
              src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/examining/submit-button-image.png"
              alt=""
          />
        </div>
      </div>
    </main>
    <van-popup
        round
        v-model="showCodePopup"
        closeable
        :close-on-click-overlay="false"
    >
      <div class="code-popup-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/fail/code.jpg"
            alt=""
        />
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/copy-text-image.png"
            alt=""
            class="copy-text-image"
            @click="copyText('51卡包')"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import {examining_click_submit_button} from '_network/statisticsUv'
import {
  get_download_url,
  get_ios_download_url
} from '_network/download'

export default {
  data() {
    return {
      product: {},
      timer: null,
      previewMoney: 50000,
      downloadUrl: '',
      // 二维码弹框
      showCodePopup: false,
      showSubmitButton: !process.env.VUE_APP_PRODUCT_DISPLAY_PAGE_NOT_DO_REDIRECT
    }
  },
  computed: {
    buttonToDownload() {
      if (
          process.env.VUE_APP_NOT_TO_SUPER_LOAN_REDIRECT_DOWNLOAD
          || localStorage.getItem('quickLoanToSuperLoan') === '1'
      ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // 用户点击底部按钮
    clickBottomButton() {
      // 主动点击时清除默认定时器
      clearTimeout(this.timer)

      // 先埋点
      examining_click_submit_button()

      // 调用页面跳转逻辑处理函数
      this.doRedirect()
    },
    async doRedirect() {
      // 特殊产品直接跳下载
      if (this.buttonToDownload) {
        if (this.isWeChat()) {
          // 微信端不能直接跳下载，要跳下载页
          this.$toDownload(0)
        } else {
          // 其他h5直接下载
          location.href = this.downloadUrl
        }
        return
      }

      await this.$router.replace('/superLoan')
    },
    doCall() {
      window.location.href = 'tel:' + this.product.customerPhone
    },
    // 复制文案至剪切板
    copyText(text) {
      const inputElement = document.createElement('input')
      inputElement.value = text
      document.body.appendChild(inputElement)
      inputElement.select()
      document.execCommand('copy')
      document.body.removeChild(inputElement)
      this.$toast.success('复制成功')
    },
    // 获取下载地址
    async getDownloadUrl() {
      const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      try {
        if (isIos) {
          // 获取ios下载链接
          const {data} = await get_ios_download_url()
          if (data) {
            // 获取到了地址直接赋值
            this.downloadUrl = data
          } else {
            // 没有获取到地址通过另一个接口获取
            const {data} = await get_download_url({onlyKey: 1})
            this.downloadUrl = data
          }
        } else {
          // 获取android下载链接
          const {data} = await get_download_url({onlyKey: 2})
          this.downloadUrl = data
        }
      } catch (e) {

      }
    },
    isWeChat() {
      const systemInfo = window.navigator.userAgent.toLowerCase()
      if (systemInfo.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    this.getDownloadUrl()

    if (process.env.VUE_APP_HAS_CODE_POPUP) {
      setTimeout(() => {
        this.showCodePopup = true
      }, 3000)
    }

    // 产品信息
    this.product = JSON.parse(localStorage.getItem('bigLoanProduct'))

    // 这里获取的用户信息用于页面的额度展示
    const userInfo = this.$getUserInfo()
    switch (userInfo.loanMoney) {
      case '20万以上':
        this.previewMoney = 300000
        break
      case '10-20万':
        this.previewMoney = 200000
        break
      case '5-10万':
        this.previewMoney = 100000
        break
      case '1-5万':
        this.previewMoney = 50000
        break
      case '2000元-1万':
        this.previewMoney = 10000
        break
    }
  },
  mounted() {
    // 如果当前底部按钮处于展示状态，根据配置项获取的跳转时间进行跳转
    if (this.showSubmitButton) {
      // 特殊渠道固定6s，特殊产品固定3s，其他渠道根据存储字段获取
      let countDown = localStorage.getItem('examiningToSuperLoanCountDown') * 1000

      if (process.env.VUE_APP_NOT_TO_SUPER_LOAN_REDIRECT_DOWNLOAD) {
        countDown = 6000
      } else if (localStorage.getItem('quickLoanToSuperLoan') === '1') {
        countDown = 3000
      }

      // 0代表不自动跳
      if (countDown !== 0) {
        this.timer = setTimeout(() => {
          this.doRedirect()
        }, countDown)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
